/* TitTap Editor Design */
.ProseMirror {
    padding: 10px;
    /* border-top: 0.7px solid black; */
    background: white;
    border-radius: 0 0 5px 5px;
    min-height: 100px;
}
.ProseMirror:focus {
    border: none;
    outline: 1px solid aqua;
}

.ProseMirror > * + * {
    margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
    /* padding: 0 2rem; */
    padding: 0 0rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
    line-height: 1.1;
}

.ProseMirror code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
}

.ProseMirror pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
}
.ProseMirror code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
}

.ProseMirror img {
    max-width: 100%;
    height: auto;
}

.ProseMirror blockquote {
    padding-left: 1rem;
    border-left: 3px solid #999999;
}

.ProseMirror hr {
    border: none;
    border-top: 3px solid #999999;
    margin: 2rem 0;
}

/* ............................ */
.textEditor {
    border-radius: 5px;
    border: 1px solid grey;
}
.menuBar {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.menuBar button {
    font-size: 18px;
    margin: 7px;
    margin-right: 15px;
    outline: none;
    border: none;
    background: none;
    color: rgb(70, 70, 70);
    cursor: pointer;
}
.menuBar button:last-child {
    margin-right: 7px;
}

.heading3 {
    font-size: 15px;
}

button.is_active {
    background: rgb(197, 197, 197);
    padding: 2px 3px;
    border-radius: 2px;
}
