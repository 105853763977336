.shrinkToTwoLines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shrinkToOneLine {
    -webkit-line-clamp: 1 !important;
}

.statusHighlight {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* .scrollBar:hover .scrollBar::-webkit-scrollbar{
    display: block;
} */

.scrollBar::-webkit-scrollbar {
    height: 5px !important;
    /* display: none; */
}
.scrollBar::-webkit-scrollbar-track {
    width: 5px !important;
}
/* .scrollBar::-webkit-scrollbar-thumb {
  background-color: #636768b2;
    border-radius: 5px !important;
} */

.arrow {
    width: 20px;
    height: 22px;
}

/* .arrow:hover { */
/* clip-path: polygon(0 10px,calc(100% - 15px) 10px,calc(100% - 15px) 0,100% 50%,calc(100% - 15px) 100%,calc(100% - 15px) calc(100% - 10px),0 calc(100% - 10px)); */
/* animation: a1 0.8s linear; */
/* } */

@keyframes a1 {
    90%,
    100% {
        flex-grow: 2;
    }
}
