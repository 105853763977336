@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer utilities {
   .arrowDown {
       clip-path: polygon(100% 0, 0 0, 50% 37%);
       /* filter:drop-shadow(5px 10px 5px rgba(0,0,0,0.7)); */
   }
   .parent:hover .child{
    display: block !important;
   }

} 

.numberClass::-webkit-outer-spin-button,
.numberClass::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.scroll::-webkit-scrollbar {
  width: 8px;
}
ul{
  list-style-type: disc; 
  margin-left: 30px;
  /* You can use other values like 'circle', 'square', etc. */
}
ol{
  list-style-type: decimal;
   margin-left: 30px;
}
 
.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(74, 73, 73, 0.3);
  border-radius: 5px;

}
 
.scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  /* outline: 1px solid slategrey; */
  border-radius: 5px;
}

input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }